import * as React from "react";
import process from "process";
import { Web3ReactProvider } from "@web3-react/core";

import { Grid, GridItem, Box, Center, Image, Input, Text, Container, Tooltip } from "@chakra-ui/react";
import keccak256 from "keccak256";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Social from "../components/social";
import { WAITLIST_MERKLE_TREE, TEAM_MERKLE_TREE, ALLOWLIST_MERKLE_TREE } from "../freemint-config";

import freeMintGif from "../images/DEATH_GIRL_HALLOWEEN_PLACEHOLDER_GIF.gif";

import "@fontsource/vt323";

if (typeof window !== "undefined") {
    // global.window = {}
    window.process = process;
}

const MerkleProofPage = () => {
    const [value, setValue] = React.useState("");
    const handleChange = (event) => setValue(event.target.value);
    const [list, setList] = React.useState();
    const [proof, setProof] = React.useState([]);

    const getAllowlistProof = (address) => {
        const hashedAddr = keccak256(address);
        const addrProof = ALLOWLIST_MERKLE_TREE.getHexProof(hashedAddr);

        return addrProof;
    };

    const getWaitlistProof = (address) => {
        const hashedAddr = keccak256(address);
        const addrProof = WAITLIST_MERKLE_TREE.getHexProof(hashedAddr);

        return addrProof;
    };

    const getTeamProof = (address) => {
        const hashedAddr = keccak256(address);
        const addrProof = TEAM_MERKLE_TREE.getHexProof(hashedAddr);

        return addrProof;
    };

    const merkleMap = {
        Team: getTeamProof,
        Allowlist: getAllowlistProof,
        Waitlist: getWaitlistProof,
    };

    const getAddressDetails = (addr) => {
        const hashedAddr = keccak256(addr);
        const onTeamList = TEAM_MERKLE_TREE.verify(TEAM_MERKLE_TREE.getHexProof(hashedAddr), hashedAddr, TEAM_MERKLE_TREE.getRoot().toString("hex"));
        const onWaitlist = WAITLIST_MERKLE_TREE.verify(WAITLIST_MERKLE_TREE.getHexProof(hashedAddr), hashedAddr, WAITLIST_MERKLE_TREE.getRoot().toString("hex"));
        const onAllowList = ALLOWLIST_MERKLE_TREE.verify(ALLOWLIST_MERKLE_TREE.getHexProof(hashedAddr), hashedAddr, ALLOWLIST_MERKLE_TREE.getRoot().toString("hex"));

        setList(onTeamList ? "Team" : onAllowList ? "Allowlist" : onWaitlist ? "Waitlist" : "Public");
        setProof(onTeamList ? getTeamProof(addr) : onAllowList ? getAllowlistProof(addr) : onWaitlist ? getWaitlistProof(addr) : "Don't need merkle proof for public mint.");

        console.log(list, proof);
    };
    React.useEffect(() => {
        if (value.length > 41 && value.length < 43 && value.startsWith("0x")) {
            getAddressDetails(value);
        }
    }, [value]);
    return (
        <Layout h="100vh">
            <Seo title="DEATH GIRL HALLOWEEN MINT | NFT" />
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(12, 1fr)"]} gap="0">
                <GridItem colSpan={5}>
                    <Center h={["auto", "100%"]}>
                        <Image src={freeMintGif} alt="DEATHGIRL HALLOWEEN MINT" />
                    </Center>
                </GridItem>
                <GridItem colSpan={7}>
                    <Center h={["auto", "100vh"]} pt={["24", "2"]} color="white" fontFamily="VT323" textAlign="center">
                        <Box minH="320px" w="100%" borderLeft={["none", "1px solid #fff"]} textAlign="center">
                            <Center>
                                <Container w="max" ml="auto" mr="auto">
                                    <Text mb="8px w-100">Address: {value}</Text>
                                </Container>
                            </Center>
                            <Center>
                                <Container w="full">
                                    <Input value={value} onChange={handleChange} placeholder="Your Ethereum Address" size="sm" />
                                </Container>
                            </Center>
                            <Center>
                                <Container w="full" mt="6">
                                    <Text>You can use: {list} Mint</Text>
                                    <Text>Merkle Proof:</Text>
                                    <Tooltip label="Click text to copy to clipboard" bg="red" hasArrow>
                                        <Text
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(proof.join(","));
                                            }}
                                        >
                                            [{proof.join(",")}]
                                        </Text>
                                    </Tooltip>
                                </Container>
                            </Center>
                        </Box>
                    </Center>
                </GridItem>
            </Grid>
            <Box clear="left" mt={["24", "-24"]}>
                <Social />
            </Box>
        </Layout>
    );
};

export default MerkleProofPage;
